import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {changeLanguage, getContextRoot, getFooterData, getLanguageValue} from "../common/functions";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Flag from "./Flag";
import { ACTIONS } from "../redux/actions";
import CONSTANTS from '../common/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function MainFooter(props) {

    const dispatch = useDispatch();
    const footerData = getFooterData();
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);

    return <MainFooterStyle className={props.className}>
        <div className="footer-logo-container">
            <img className="footer-logo" src={getContextRoot() + 'images/logo.png'} />
        </div>
        <Grid className="columns-container" container direction={isMobile ? 'column' : 'row'}>
            {footerData.map(function(footerDataItem, footerDataItemIndex) {
                return <Grid key={footerDataItemIndex} className="column-item" item xs>
                    <div className="column-title">{footerDataItem.title}</div>
                    <div dangerouslySetInnerHTML={{__html: footerDataItem.html}} />
                </Grid>;
            })}
        </Grid>
        <div className="all-rights-reserved-container">
            © GOVIS LLC {new Date().getFullYear()}, כל הזכויות שמורות <span style={{ color: 'gray' }}>{CONSTANTS.BUILD_VERSION_STRING}</span>
        </div>
        <div className="legal-disclaimer-container" dangerouslySetInnerHTML={{ __html: getLanguageValue('footer.legal-disclaimer') }} />
    </MainFooterStyle>;
}

const MainFooterStyle = styled.div`
  background: url('${getContextRoot() + 'images/footer_bg.jpg'}');
  background-repeat: no-repeat;
  background-size: 100%;
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
    background-size: cover;
    padding-bottom: 150px;
  }
  color: white;
  padding-top: 15px;
  padding-bottom: 20px;
  font-size: 18px;
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
    html.rtl & {
      padding-right: 25px;
    }
    html.ltr & {
      padding-left: 25px;
    }
  }
  a, a:visited {
    font-weight: bold;
    color: white;
  }
  .footer-logo-container {
    text-align: center;
  }
  .footer-logo {
    height: 100px;
  }
  .columns-container {
    max-width: 800px;
    margin: 0 auto;
    padding-top: 15px;
  }
  .column-item {
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        padding-bottom: 40px;
      }
  }
  .column-title {
    font-size: 25px;
    font-weight: bold;
    color: #F17422;
    margin-bottom: 20px;
    min-height: 60px;
  }
  .all-rights-reserved-container {
    text-align: center;
    direction: ltr;
    margin-top: 25px;
  }
  .legal-disclaimer-container {
    margin-top: 10px;
    text-align: center;
  }
`;


export default MainFooter;
